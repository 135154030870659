import { useEffect, useState } from "react";
import { Plan } from "../../utils/commonTypes";
import { useLoginContext } from "../../utils/LoginContext";
import { VuiFlexContainer, VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { readPricingPlans } from "../../api/api";
import { useCustomerContext } from "../../utils/CustomerContext";
import { dateFromInstant } from "../../utils/commonFunctions";

export const CustomerPlanHistory = () => {
  const [customerPlans, setCustomerPlans] = useState<Array<Plan>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authToken, isAuthenticated } = useLoginContext();
  const { customer } = useCustomerContext();

  const fetchCustomerPlanHistory = async () => {
    if (!authToken || !isAuthenticated) return;
    setIsLoading(true);
    try {
      const plans = await readPricingPlans(customer.customerId, authToken);
      setCustomerPlans(plans);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerPlanHistory();
  }, []);

  const columns = [
    {
      name: "startDate",
      header: {
        render: () => {
          return "Start Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return dateFromInstant(plan.startDate).toDateString();
      }
    },
    {
      name: "planName",
      header: {
        render: () => {
          return "Plan Name";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planName;
      }
    },
    {
      name: "planId",
      header: {
        render: () => {
          return "Plan ID";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planId;
      }
    },
    {
      name: "expiryDate",
      header: {
        render: () => {
          return "Expiry Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        if (plan.expiryDate === undefined || plan.expiryDate === 0) {
          return "N/A";
        }
        return dateFromInstant(plan.expiryDate).toDateString();
      }
    },
    {
      name: "planDescription",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planDescription;
      }
    }
  ];

  return (
    <VuiFlexContainer direction="column" className="plan-history-width">
      <VuiText className="font-bold text-base">Plan History</VuiText>
      {isLoading ? (
        <VuiText>
          Loading plan history, please wait ...
          <VuiSpinner size="m" />
        </VuiText>
      ) : (
        <VuiTable idField={(plan: Plan) => plan.planId} columns={columns} rows={customerPlans} />
      )}
    </VuiFlexContainer>
  );
};
