export function formatBytes(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = Math.pow(10, dp);
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
}

export const MebiBytesToBytes = (mb: number): number => {
  return mb * 1024 * 1024;
};

export const BytesToMebiBytes = (bytes: number): number => {
  return bytes / 1024 / 1024;
};

export const shortMonthName = (month: string): string => {
  const shortMonthNameMap: { [key: string]: string } = {
    january: "Jan",
    february: "Feb",
    march: "Mar",
    april: "Apr",
    may: "May",
    june: "Jun",
    july: "Jul",
    august: "Aug",
    september: "Sep",
    october: "Oct",
    november: "Nov",
    december: "Dec"
  };
  return shortMonthNameMap[month] ?? month;
};

export const dateFromInstant = (instant: number): Date => {
  return new Date(instant * 1000);
};
