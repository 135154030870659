import {
  VuiButtonSecondary,
  VuiFlexContainer,
  VuiLink,
  VuiSpacer,
  VuiSpinner,
  VuiTable,
  VuiText,
  VuiTextInput
} from "@vectara/vectara-ui";
import { useState } from "react";
import { useLoginContext } from "../utils/LoginContext";
import { searchCustomers } from "../api/api";
import { Customer } from "../utils/commonTypes";
import { useNavigate } from "react-router";
import { useCustomerContext } from "../utils/CustomerContext";

export const SearchCustomer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState<Array<Customer>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { updateCustomer } = useCustomerContext();
  const { authToken, isAuthenticated } = useLoginContext();
  const navigate = useNavigate();

  const doSearch = async () => {
    if (isLoading) return;
    if (!searchTerm) {
      setSearchedCustomers([]);
      return;
    }
    setIsLoading(true);
    try {
      if (authToken && isAuthenticated) {
        const customers = await searchCustomers(authToken, searchTerm);
        setSearchedCustomers(customers);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToCustomer = (customer: Customer) => () => {
    // Set customer in user context here.
    updateCustomer(customer);
    navigate(`/customer/${customer.customerId}`);
  };

  const columns = [
    {
      name: "id",
      header: {
        render: () => {
          return "Customer Id";
        }
      },
      render: (customer: Customer) => {
        if (!customer) return;
        return (
          <>
            <VuiLink onClick={navigateToCustomer(customer)}>{customer.customerId}</VuiLink>
          </>
        );
      }
    },
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (customer: Customer) => {
        if (!customer) return;
        return <>{customer.userName}</>;
      }
    },
    {
      name: "email",
      header: {
        render: () => {
          return "Email";
        }
      },
      render: (customer: Customer) => {
        if (!customer) return;
        return <>{customer.userEmail}</>;
      }
    }
  ];

  const tableContent = !searchCustomers?.length ? (
    <VuiText>
      <p>No customers found.</p>
    </VuiText>
  ) : undefined;

  return (
    <div className="pt-5 pl-10">
      <VuiText className="switch-description">
        Search a customer by name, email, or customer id. Click on the customer id to view the customer details.
      </VuiText>
      <VuiSpacer size="l" />
      <VuiFlexContainer>
        <VuiTextInput
          placeholder="Search customers"
          onSubmit={doSearch}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <VuiButtonSecondary onClick={doSearch} color={"accent"}>
          {isLoading ? <VuiSpinner size="xs" /> : "Search"}
        </VuiButtonSecondary>
      </VuiFlexContainer>
      <VuiSpacer size="l" />
      <VuiTable
        fluid
        idField={(customer: Customer) => customer.customerId.toString()}
        columns={columns}
        rows={searchedCustomers}
        content={tableContent}
      />
    </div>
  );
};
